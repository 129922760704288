import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import Styles from './About.module.scss'
import CEOImage from '../../assets/Images/CeoImage.png'
import MapImage from '../../assets/Images/MapImage.png'
import StoryBgImage from '../../assets/Images/StoryBgImage.png'
import AOS from 'aos';
import BgLogo from '../../assets/Images/BgLogo.png';

const About = () => {

  React.useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of the animation in milliseconds
    });
  }, [])

  return (
    <Box className={Styles.container}>
      <Box className={Styles.aboutHeaderContainer}>
        <Box className={Styles.headerBox}>
          <Typography className={Styles.headerTitle}>About AdvantEdge</Typography>
          <Typography className={Styles.headerContent}>AdvantEdge is a NGLCC (LGBTQ+) certified workforce solutions company supporting individuals<br />
            looking for their next career opportunity and client partners in need of expert talent and workforce <br />solutions. Our passion for Diversity, Equity, and Inclusion informs all aspects of how we approach <br /> every opportunity, engagement, and individual</Typography>
        </Box>
        <Grid container spacing={4} mt={4} className={Styles.headerSecondBox}>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={Styles.leftHeaderBox} >
              <Typography className={Styles.leftHeaderTitle} data-aos="fade-down">Our Mission Statement</Typography>
              <Typography className={Styles.leftHeaderContent} data-aos="fade-down">AdvantEdge is committed to building a diverse, equitable, and inclusive workforce that reflects the vibrant diversity of our global community. Our mission is to connect exceptional talent with inclusive organizations, empowering both individuals and businesses to thrive together.</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={Styles.rightHeaderBox}>
              <Typography className={Styles.rightHeaderTitle} data-aos="fade-down">Our Vision Statement</Typography>
              <Typography className={Styles.rightHeaderContent} data-aos="fade-down">Our vision is to be the leader in creating a diverse and inclusive workforce, where the best of each individual is matched with the best of our client partners. Our unwavering commitment is to shape a future where diversity is celebrated, equity is the norm, and inclusion is the cornerstone of thriving workplaces.</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className={Styles.ceoContainer} data-aos="fade-up">
        <Box className={Styles.ceoHeaderContainer} >
          <Box className={Styles.ceoImageBox} >
            <img src={CEOImage} alt='/' />
          </Box>
          <Box className={Styles.ceoContentBox} >
            <Typography className={Styles.ceoContentTitle}>CEO Statement</Typography>
            <Typography className={Styles.ceoContentContent}>Diversity, Equity & Inclusion - Our <br />Commitment</Typography>
          </Box>
        </Box>
        <Box className={Styles.ceoFooterContainer} >
          <Typography className={Styles.ceoFooterContent}>At AdvantEdge, our commitment extends beyond fostering a diverse, equitable, and inclusive workplace. By <br /> embracing diversity, equity, and inclusion, our aim is to create an environment and partnerships where <br /> everyone feels they belong and can thrive. This commitment is not just internal; it reaches out into the <br /> community, recognizing the duty to support and elevate those who may not have the same advantages. It's <br />a holistic view that sees success not just in business achievements, but in the positive impact on society as a whole.</Typography>
        </Box>
      </Box>
      <Box className={Styles.locationContainer}>
        <Box className={Styles.mapContentContainer}>
          <Typography className={Styles.mapTitle}>Global Locations</Typography>
          <Typography className={Styles.mapContent}>In partnership with our affiliate ecosystem, our global footprint includes <br />
            US, Canada, UK, India, Australia, Portugal, and Philippines</Typography>
          <Box className={Styles.mapContainer} mt={2}>
            <img src={MapImage} alt='/' />
          </Box>
        </Box>
      </Box>
      <Box className={Styles.storyContainer}>
        <Box className={Styles.storyImageContainer}>
          <img src={StoryBgImage} alt='/' data-aos="fade" />
          <Box className={Styles.storyContentContainer}>
            <Typography className={Styles.storyTitle}>Our Story</Typography>
            <Typography className={Styles.storyContent}>AdvantEdge was founded in 2015 as a project-based technology solutions company. Our focus was providing expert teams of IT professionals to collaborate with clients to identify needs, objectives, and goals to deliver creative solutions. In response to expanding client needs, our business model and service offerings evolved to include  additional workforce solutions that include Contract and Fulltime recruitment, EOR,  AOR, Payroll administration, Independent Contractor Compliance, Recruitment Process  Outsourcing (RPO), and Workforce Consulting. We pride ourselves on being the premier staffing agency. We work with the best talent and best clients</Typography>
          </Box>
        </Box>
      </Box>

      <Box className={Styles.diversityContainer}>
        <Typography className={Styles.dicersityTitle}>Diversity Commitment</Typography>
        <Typography className={Styles.dicersityContent}>AdvantEdge is deeply committed to representing and reflecting the diversity of our Team, Partners, and <br /> communities with respect to their unique experiences, perspectives and viewpoints. To us, Diversity is <br /> inclusion, acceptance, honoring differences, and respect. As a certified LGBTE diverse company, we are <br /> intentional in our efforts to include as many candidates from diverse communities as possible. We believe <br /> that our entire Team shares the responsibility of upholding our values of equity and inclusion. Our Diversity, <br /> Equity & Inclusion (DEI) team takes the lead in working to increase awareness and accountability and <br />promote active allyship throughout the company.</Typography>
      </Box>
      <Box className={Styles.bgLogo1}>
        <img src={BgLogo} alt="/" />
      </Box>
      <Box className={Styles.bgLogo2}>
        <img src={BgLogo} alt="/" />
      </Box>
      <Box className={Styles.bgLogo3}>
        <img src={BgLogo} alt="/" />
      </Box>
      <Box className={Styles.bgLogo4}>
        <img src={BgLogo} alt="/" />
      </Box>
      <Box className={Styles.bgLogo5}>
        <img src={BgLogo} alt="/" />
      </Box>
      <Box className={Styles.bgLogo6}>
        <img src={BgLogo} alt="/" />
      </Box>
    </Box>
  );
};

export default About;
