import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/Images/HeaderLogo.png'
import { Box, Button, Divider, Drawer, Typography } from '@mui/material';
import Styles from './Header.module.scss'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AOS from 'aos';

const Header = () => {

  const location = useLocation();
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)
  const activeNavigate = (activePathname) => {
    return location.pathname === activePathname
  }

  const handleMobileNavigate = (path) => {
    navigate(path)
    setOpen(false)
  }

  const handleNavigate = (path) => {
    navigate(path)
    window.scrollTo({
      top:0,
      behavior:'smooth'
  })
  }
  
  React.useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of the animation in milliseconds
    });
  }, [])


  return (
    <Box className={Styles.container}>
      <Box className={Styles.imageContainer}>
        <img src={Logo} alt='/logo' />
      </Box>
      <Box className={Styles.navContainer}>
        <ul>
          <li className={activeNavigate('/') ? Styles.linkActiveText : Styles.linkInActiveText} onClick={() => handleNavigate('/')}>Home</li>
          <li className={activeNavigate('/about') ? Styles.linkActiveText : Styles.linkInActiveText} onClick={() => handleNavigate('/about')}>About</li>
          <li className={activeNavigate('/services') ? Styles.linkActiveText : Styles.linkInActiveText} onClick={() => handleNavigate('/services')}>Services</li>
        </ul>
      </Box>
      <Box className={Styles.buttonContainer}>
        <Button className={Styles.contactButton} onClick={() => handleNavigate('/contact')}>Contact Us</Button>
      </Box>
      <Box className={Styles.menuContainer} sx={{ display: 'none' }}>
        <MenuIcon onClick={() => setOpen(true)} />
      </Box>
      <Box data-aos="flip-left">
        <Drawer
          open={open}
          anchor='top'
        >
          <Box className={Styles.mobileContentBox}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '2% 4%' }}>
              <Box className={Styles.mobileImageContaiber}>
                <img src={Logo} alt='/logo' />
              </Box>
              <CloseIcon onClick={() => setOpen(false)} />
            </Box>
            <Divider sx={{ backgroundColor: "#D0D5DD", opacity: 0.2 }} />
            <Box className={Styles.mobileContent}>
              <Typography className={Styles.title} onClick={() => handleMobileNavigate('/')}>Home</Typography>
              <Typography className={Styles.title} onClick={() => handleMobileNavigate('/about')}>About</Typography>
              <Typography className={Styles.title} onClick={() => handleMobileNavigate('/services')}>Services</Typography>
              <Typography className={Styles.title} onClick={() => handleMobileNavigate('/contact')}>Contact Us</Typography>
            </Box>
            <Divider sx={{ backgroundColor: "#D0D5DD", opacity: 0.2 }} />
          </Box>

        </Drawer>
      </Box>
    </Box >
  );
};

export default Header;
