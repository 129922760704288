import React from "react";
import Styles from './Contact.module.scss'
import { Box, Typography, Grid } from "@mui/material";
import LocationIcon from '../../assets/Images/LocationIcon.png'
import InboxIcon from '../../assets/Images/InboxIcon.png'
import CallIcon from '../../assets/Images/CallIcon.png'
import { Formik, Form, Field } from 'formik';
import AOS from 'aos';
import BgLogo from '../../assets/Images/BgLogo.png';

const Contact = () => {

    const ConfigData = [
        {
            img: CallIcon,
            title: "Talk with our team",
            value: "+1 734 - 224 - 6644"
        },
        {
            img: InboxIcon,
            title: "Email",
            value: "info@advantedgets.com"
        },
        {
            img: LocationIcon,
            title: "Address",
            value: "581 State Circle, Ste.A",
            value2:"Ann Arbor, MI 48108"
        }
    ]
    
    React.useEffect(() => {
        AOS.init({
            duration: 1000, 
        });
    }, [])

    return (
        <Box className={Styles.container}>
            <Box className={Styles.leftContainer} data-aos="fade-right">
                <Box className={Styles.titleContainer}>
                    <Typography className={Styles.title}>Contact AdvantEdge</Typography>
                    <Typography className={Styles.content}>Interested in Learning more? Questions about our <br />Workforce Solutions, Consulting Services, or available<br /> opportunities? Our Team of professionals is ready to help.</Typography>
                </Box>
                <Grid container className={Styles.contactBigBox} rowGap={4} mt={4}>
                    {
                        ConfigData.map((e, i) => (
                            <Grid item xs={12} sm={12} key={i} className={Styles.contactBox}>
                                <Box>
                                    <img src={e.img} alt="/" />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' , gap:'10px'}}>
                                    <Typography className={Styles.contactTitle}>{e.title}</Typography>
                                    <Typography className={Styles.contactValue}>{e.value}<br/>{e.value2}</Typography>
                                </Box>
                            </Grid>
                        ))
                    }
                </Grid>
            </Box>
            <Box className={Styles.rightContainer} data-aos="fade-left">
                <Typography className={Styles.contactUsTitle}>Contact Us</Typography>
                <Formik
                    initialValues={{
                        name: '',
                        email: '',
                    }}
                >
                    {() => (
                        <Form>
                            <Grid container mt={2} rowGap={2}>
                                <Grid item xs={12}>
                                    <Typography className={Styles.inputTitle}>Name</Typography>
                                    <Field name="name" className={Styles.inputBox} placeholder="Name" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={Styles.inputTitle}>Email</Typography>
                                    <Field name="email" className={Styles.inputBox} placeholder="you@company.com" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={Styles.inputTitle}>Phone Number</Typography>
                                    <Field name="number" className={Styles.inputBox} placeholder="US +1 (555) 000-0000" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={Styles.inputTitle}>Message</Typography>
                                    <Field as="textarea" style={{ height: "100px" }} name="message" className={Styles.inputBox}  />
                                </Grid>
                                <Grid item xs={12} mt={{ xs: 1, sm: 4 }}>
                                    <button type="submit">Send Message</button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
            <Box className={Styles.bgLogo1}>
                <img src={BgLogo} alt="/" />
            </Box>
            <Box className={Styles.bgLogo2}>
                <img src={BgLogo} alt="/" />
            </Box>
            <Box className={Styles.bgLogo3}>
                <img src={BgLogo} alt="/" />
            </Box>
        </Box>
    )
}

export default Contact