import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import Styles from './Home.module.scss'
import NgLogo from '../../assets/Images/NGLCC_Logo.png'
import AdvManImage from '../../assets/Images/AdvManImage.png'
import EastIcon from '@mui/icons-material/East';
import LeverageImageOne from '../../assets/Images/LeverageImgOne.png'
import LeverageImgTwo from '../../assets/Images/LeverageImgTwo.png'
import EmpowerImage from '../../assets/Images/EmpoverImage.png'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import AOS from 'aos';
import BgLogo from '../../assets/Images/BgLogo.png';
import DarkBgLogo from '../../assets/Images/DarkBgLogo.png'
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate()

  const handleNavigate = (path) => {
    navigate(path)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  React.useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of the animation in milliseconds
    });
  }, [])
  return (
    <Box className={Styles.container}>
      <Box className={Styles.headerContainer}>
        <Box>
          <Typography className={Styles.leverTitle}>Leveraging the best of</Typography>
          <Typography className={Styles.leverTitle}>who <span>YOU</span> are.</Typography>
        </Box>
        <Box>
          <Typography className={Styles.adventureTitle}>“AdvantEdge is your partner in workforce management,</Typography>
          <Typography className={Styles.adventureTitle}>matching the best talent with the best opportunity.”</Typography>
        </Box>
        <Box className={Styles.nglccImageContainer} mt={2}>
          <img src={NgLogo} alt="/" />
        </Box>
        <Box mt={2.4} className={Styles.buttonsGroup}>
          <Button className={Styles.buttonOne} >Join our Talent Pool</Button>
          <Button className={Styles.buttonTwo}>Become Our Client Partner</Button>
        </Box>
      </Box>
      <Box className={Styles.advantEdgeContainer}>
        <Box className={Styles.advImageContainer} data-aos="fade">
          <img src={AdvManImage} alt='/' />
        </Box>
        <Box className={Styles.contentContainer}>
          <Typography className={Styles.headline}>AdvantEdge is a <br />NGLCC (LGBTQ+) <br />
            certified workforce<br />
            solutions company</Typography>
          <Typography className={Styles.contentOne}>We support individuals looking for their next career opportunity and client partners in need of expert talent acquisition and workforce solutions. Our passion for Diversity, Equity, and Inclusion informs all aspects of how we approach every opportunity, engagement, and individual.
          </Typography>
          <Typography className={Styles.contentTwo}>In partnership with our affiliate ecosystem, our global footprint includes: US, Canada, UK, India, Australia, Portugal, and Philippines.</Typography>
          <Button  endIcon={<EastIcon />} className={Styles.learnMoreButton}>Learn More</Button>
          <Box className={Styles.bgLogo1}>
            <img src={BgLogo} alt="/" />
          </Box>
          <Box className={Styles.bgLogo2}>
            <img src={BgLogo} alt="/" />
          </Box>
        </Box>
      </Box>
      <Box className={Styles.leveragingContainer}>
        <Box className={Styles.leverageRightContainer}>
          <Box className={Styles.leveImgOneContainer} data-aos="fade-down">
            <img src={LeverageImageOne} alt='/' />
            <Box className={Styles.whiteSpace} />
            <Box className={Styles.orangeBox}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={Styles.yearsCount}>9</Typography>
                <span>+</span>
              </Box>
              <Typography className={Styles.yearsTitle}>Years Experience</Typography>
            </Box>
          </Box>
          <Box className={Styles.leveImgTwoContainer} data-aos="fade-up">
            <img src={LeverageImgTwo} alt='/' />
          </Box>
        </Box>
        <Box className={Styles.leverageLeftContainer} data-aos="fade-left">
          <Typography className={Styles.levageTitle}>Leveraging The Best Of Who You Are</Typography>
          <Typography className={Styles.levageParah}>At AdvantEdge, we believe everyone has something they are exceptional at, a skill or talent, that sets them apart from others. We also believe the same is true of our client partners.</Typography>
          <Typography className={Styles.levageParah}>Understanding what sets you apart empowers us to align you with the best opportunities, or talent, to set you up for success.</Typography>
        </Box>
        <Box className={Styles.bgLogo5}>
          <img src={DarkBgLogo} alt="/" />
        </Box>
        <Box className={Styles.bgLogo6}>
          <img src={DarkBgLogo} alt="/" />
        </Box>
      </Box>

      <Box className={Styles.empowerContainer}>
        <Box className={Styles.leftContainer} data-aos="fade-right">
          <Typography className={Styles.empowerTitle}>Empowering Workforce <br/>
            Solutions <span>for Success</span></Typography>
          <Typography className={Styles.empowerContent}>“AdvantEdge is passionate about empowering individuals and our client
            partners to thrive in today's dynamic world of work. As a leading national
            workforce solutions provider, we specialize in sourcing, assessing,
            developing, and managing talent. Our focus is connecting individuals to
            meaningful, sustainable employment while enabling businesses to achieve
            their goals.”</Typography>
          <Button className={Styles.viewAllButton} onClick={() => handleNavigate('/services')} endIcon={<KeyboardDoubleArrowRightIcon />}>View All services</Button>
        </Box>
        <Box className={Styles.rightContainer}>
          <img src={EmpowerImage} alt='/' />
        </Box>
      </Box>

      <Box className={Styles.bgLogo3}>
        <img src={BgLogo} alt="/" />
      </Box>
      <Box className={Styles.bgLogo4}>
        <img src={BgLogo} alt="/" />
      </Box>

    </Box>
  );
};

export default Home;
