import React, { useState } from 'react';
import Logo from '../../assets/Images/ServiceImage.png';
import { Box, Grid, Typography } from '@mui/material';
import Styles from './Services.module.scss';
import SerImg1 from '../../assets/Images/ServiceImage1.png';
import SerImg2 from '../../assets/Images/ServiceImage2.png';
import SerImg3 from '../../assets/Images/ServiceImage3.png';
import SerImg4 from '../../assets/Images/ServiceImage4.png';
import SerImg5 from '../../assets/Images/ServiceImage5.png';
import HvrSerImg1 from '../../assets/Images/HvrServiceImage1.png';
import HvrSerImg2 from '../../assets/Images/HvrServiceImage2.png';
import HvrSerImg3 from '../../assets/Images/HvrServiceImage3.png';
import HvrSerImg4 from '../../assets/Images/HvrServiceImage4.png';
import HvrSerImg5 from '../../assets/Images/HvrServiceImage5.png';
import BgLogo from '../../assets/Images/BgLogo.png';

const Services = () => {
  const [hoverStates, setHoverStates] = useState({});

  const ServiceConfig = [
    {
      normalImage: SerImg1,
      hoverImage: HvrSerImg1,
      title1: "Contract & Permanent ",
      title2: "Recruitment Services",
      content: "Supporting professionals seeking next steps on their career journey and client partners in need of top talent to meet strategic business objectives."
    },
    {
      normalImage: SerImg2,
      hoverImage: HvrSerImg2,
      title1: "Recruitment Process ",
      title2: "Outsourcing (RPO)",
      content: "Partnering with clients to provide white label talent acquisition teams that understand what it means, and what it takes to be a true brand ambassador."
    },
    {
      normalImage: SerImg3,
      hoverImage: HvrSerImg3,
      title1: "Diverse Certification ",
      title2: "Consulting Services",
      content: "Providing 1-on-1 guidance with navigating the certification process for: NGLCC, WBENC, NMSDC, NVBDC."
    },
    {
      normalImage: SerImg4,
      hoverImage: HvrSerImg4,
      title1: "Payroll and ",
      title2: "IC Compliance",
      content: "EOR (Employer of Record), AOR (Agency of Record), and Independent Contractor Compliance."
    },
    {
      normalImage: SerImg5,
      hoverImage: HvrSerImg5,
      title1: "Workforce Consulting ",
      title2: "Services",
      content: "Partnering with Clients to understand their unique needs and providing solutions that drive success."
    },
  ];

  const handleMouseEnter = (index) => {
    setHoverStates((prev) => ({ ...prev, [index]: true }));
  };

  const handleMouseLeave = (index) => {
    setHoverStates((prev) => ({ ...prev, [index]: false }));
  };

  return (
    <Box className={Styles.container}>
      <Box className={Styles.logoContainer}>
        <img src={Logo} alt="/" />
      </Box>
      <Box className={Styles.contentBigContainer}>
        <Typography className={Styles.servicesTitle}>Our Services</Typography>
        <Grid container spacing={6} className={Styles.gridContainer}>
          {ServiceConfig.map((e, i) => (
            <Grid item xs={12} md={6} sm={12} key={i}>
              <Box
                className={Styles.contentContainer}
                onMouseEnter={() => handleMouseEnter(i)}
                onMouseLeave={() => handleMouseLeave(i)}
              >
                <Box className={Styles.header}>
                  <img src={hoverStates[i] ? e.hoverImage : e.normalImage} alt='logo' style={{ height: '48px', width: '48px' }} />
                  <Box>
                    <Typography className={Styles.headerTitle}>{e.title1}</Typography>
                    <Typography className={Styles.headerTitle}>{e.title2}</Typography>
                  </Box>
                </Box>
                <Typography className={Styles.content}>{e.content}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box className={Styles.bgLogo1}>
        <img src={BgLogo} alt="/" />
      </Box>
      <Box className={Styles.bgLogo2}>
        <img src={BgLogo} alt="/" />
      </Box>
    </Box>
  );
};

export default Services;
